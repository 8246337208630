<template>
  <b-row>
     <b-col lg="12" sm="12">
         <b-overlay :show="loader">
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                  <b-row>
                    <!-- fiscal year -->
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Year" vid="year" rules="required|min_value:1"  v-slot="{ errors }">
                        <b-form-group
                          label-cols-sm="4"
                          label-for="year"
                        >
                        <template v-slot:label>
                          {{$t('globalTrans.year')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="data.year"
                          :options="yearList"
                          id="year"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Month" vid="month" rules="required|min_value:1"  v-slot="{ errors }">
                        <b-form-group
                          label-cols-sm="4"
                          label-for="month"
                        >
                        <template v-slot:label>
                          {{$t('globalTrans.month')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="data.month"
                          :options="monthList"
                          id="month"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Country Name" vid="country_id" rules="required|min_value:1"  v-slot="{ errors }">
                        <b-form-group
                          label-cols-sm="4"
                          label-for="country_id"
                        >
                        <template v-slot:label>
                          {{$t('globalTrans.country')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="data.country_id"
                          :options="countryList"
                          id="country_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
              <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset" autocomplete="off">
                  <b-row class="p-4">
                    <b-col style="background-color: var(--gray-ddd);" lg="12" class="p-2">
                      <template>
                        <h6 style="" class="text-sm-left">{{ $t('teaGardenService.export_info_quantity') }} {{ $t('globalTrans.entry')}}</h6>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <ValidationProvider name="Exporter Name" vid="export_company_id" rules="required|min_value:1"  v-slot="{ errors }">
                        <b-form-group
                          label-cols-sm="3"
                          label-for="export_company_id"
                        >
                        <template v-slot:label>
                          {{$t('teaGardenService.exporter_name')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="detailsFormData.export_company_id"
                          :options="exporterList"
                          id="export_company_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col style="background-color: var(--gray-ddd);" lg="6" class="offset-lg-3 p-2 mb-3">
                      <template>
                        <h6 style="" class="text-sm-center">{{ $t('teaGardenService.loose_tea') }}</h6>
                      </template>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Quantity" vid="loose_tea_quantity" :rules="{ regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="loose_tea_quantity"
                        >
                          <template v-slot:label>
                            {{ $t('teaGardenService.quantity') }}
                          </template>
                          <b-form-input
                            id="loose_tea_quantity"
                            v-model="detailsFormData.loose_tea_quantity"
                            @input="totalQuantity()"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="FOB Value" vid="loose_tea_fob_value" :rules="{ regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="loose_tea_fob_value"
                        >
                          <template v-slot:label>
                            {{ $t('teaGardenService.fob_value') }}
                          </template>
                          <b-form-input
                            id="loose_tea_fob_value"
                            v-model="detailsFormData.loose_tea_fob_value"
                            @input="totalFobValue()"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col style="background-color: var(--gray-ddd);" lg="6" class="offset-lg-3 p-2 mb-3">
                      <template>
                        <h6 style="" class="text-sm-center">{{ $t('teaGardenService.packet_tea') }}</h6>
                      </template>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Quantity" vid="packet_tea_quantity" :rules="{ regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="packet_tea_quantity"
                        >
                          <template v-slot:label>
                            {{ $t('teaGardenService.quantity') }}
                          </template>
                          <b-form-input
                            id="packet_tea_quantity"
                            v-model="detailsFormData.packet_tea_quantity"
                            @input="totalQuantity()"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="FOB Value" vid="packet_tea_fob_value" :rules="{ regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="packet_tea_fob_value"
                        >
                          <template v-slot:label>
                            {{ $t('teaGardenService.fob_value') }}
                          </template>
                          <b-form-input
                            id="packet_tea_fob_value"
                            v-model="detailsFormData.packet_tea_fob_value"
                            @input="totalFobValue()"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col style="background-color: var(--gray-ddd);" lg="6" class="offset-lg-3 p-2 mb-3">
                      <template>
                        <h6 style="" class="text-sm-center">{{ $t('teaGardenService.total_tea_quantity') }}</h6>
                      </template>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Quantity" vid="total_quantity" :rules="{ regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="total_quantity"
                        >
                          <template v-slot:label>
                            {{ $t('teaGardenService.quantity') }}
                          </template>
                          <b-form-input
                            id="total_quantity"
                            v-model="detailsFormData.total_quantity"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="FOB Value" vid="total_fob_value" :rules="{ regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="total_fob_value"
                        >
                          <template v-slot:label>
                            {{ $t('teaGardenService.fob_value') }}
                          </template>
                          <b-form-input
                            id="total_fob_value"
                            v-model="detailsFormData.total_fob_value"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Dollar Exchange Rate" vid="dollar_exchange_rate" :rules="{required:true, regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="dollar_exchange_rate"
                        >
                          <template v-slot:label>
                            {{ $t('teaGardenService.dollar_exchange_rate') }}<span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="dollar_exchange_rate"
                            v-model="detailsFormData.dollar_exchange_rate"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Date" vid="date" v-slot="{ errors }">
                        <b-form-group
                                label-cols-sm="5"
                                label-for="date"
                            >
                                <template v-slot:label>
                                {{ $t('globalTrans.date') }}
                                </template>
                                <date-picker
                                id="date"
                                class="form-control"
                                v-model="detailsFormData.date"
                                :class="errors[0] ? 'is-invalid' : ''"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :placeholder="$t('globalTrans.select_date')"
                                :locale="currentLocale"
                                >
                                </date-picker>
                                <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col>
                        <div class="text-right mb-2">
                            <b-button type="button" v-if="editIndex !== ''" variant="danger" class="mr-1 btn-sm" @click="cancelItem()">{{ $t('globalTrans.cancel') }}</b-button>
                            <b-button type="submit" class="btn-sm" variant="success">{{ editIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add')}}</b-button>
                        </div>
                    </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
              <br/>
            <!-- List  -->
            <b-table-simple bordered>
              <thead>
                <tr class="bg-primary">
                  <th style="width:7%" rowspan="2">{{ $t('globalTrans.sl_no') }}</th>
                  <!-- <th style="width:10%" rowspan="2">{{ $t('globalTrans.country_name') }}</th> -->
                  <th style="width:10%" rowspan="2">{{ $t('teaGardenService.exporter_name') }}</th>
                  <th style="width:10%" colspan="2">{{ $t('teaGardenService.loose_tea') }}</th>
                  <th style="width:10%" colspan="2">{{ $t('teaGardenService.packet_tea') }}</th>
                  <th style="width:10%" rowspan="2">{{ $t('globalTrans.action') }}</th>
                </tr>
                <tr class="bg-light">
                  <th style="width:10%">{{ $t('teaGardenService.quantity') }}</th>
                  <th style="width:10%">{{ $t('teaGardenService.fob_value') }}</th>
                  <th style="width:10%">{{ $t('teaGardenService.quantity') }}</th>
                  <th style="width:10%">{{ $t('teaGardenService.fob_value') }}</th>
                </tr>
              </thead>
              <b-tbody>
                  <b-tr v-for="(item, index) in data.details" :key="index">
                      <b-td>
                          {{ $n(index + 1) }}
                      </b-td>
                      <b-td>
                        {{ getCompanyName(item.export_company_id) }}
                      </b-td>
                      <b-td>
                        {{ $n(item.loose_tea_quantity) }}
                      </b-td>
                      <b-td>
                        {{ $n(item.loose_tea_fob_value) }}
                      </b-td>
                      <b-td>
                        {{ $n(item.packet_tea_quantity) }}
                      </b-td>
                      <b-td>
                        {{ $n(item.packet_tea_fob_value) }}
                      </b-td>
                      <b-td>
                        <span class="d-flex text-center">
                          <b-button @click="editItem(item, index)" title="Location Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                          <b-button type="button" @click="deleteItem(index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                        </span>
                      </b-td>
                  </b-tr>
                  <b-tr v-if="!data.details || data.details.length <= 0">
                      <td colspan="7" class="text-center">
                          <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                      </td>
                  </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-row class="text-right">
              <b-col>
                <!-- <b-button type="button" @click="saveUpdate" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button> -->
                <b-button type="button" @click="saveUpdate()" variant="warning" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
                <b-button type="button" @click="finalSave()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button>
                <b-button variant="danger" type="button" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
              </b-col>
            </b-row>
        </b-overlay>
    </b-col>
  </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { countryWiseExportStore, countryWiseExportUpdate, countryWiseExportShow } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
export default {
  name: 'Form',
  props: ['id'],
  components: { },
  data () {
    return {
      valid: null,
      loader: false,
      status: 2,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      editIndex: '',
      data: {
        year: 0,
        month: 0,
        country_id: 0,
        status: 2,
        details: []
      },
      detailsFormData: {
        export_company_id: 0,
        loose_tea_quantity: '',
        loose_tea_fob_value: '',
        packet_tea_quantity: '',
        packet_tea_fob_value: '',
        total_quantity: '',
        total_fob_value: '',
        dollar_exchange_rate: '',
        date: ''
      }
    }
  },
  created () {
    this.data.year = new Date().getFullYear()
    if (this.id) {
      const tmp = this.getCountryWiseExportInfo()
      this.data = tmp
    }
  },
  watch: {
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    yearList () {
      return helpers.getYearList()
    },
    monthList: function () {
      return this.$store.state.commonObj.monthList.map(el => {
        return Object.assign(el, {}, { text: this.$i18n.locale === 'en' ? el.text_en : el.text_bn })
      })
    },
    countryList () {
      return this.$store.state.CommonService.commonObj.countryList.filter(item => item.status === 1 && (item.text_en.toLowerCase() !== 'bangladesh'))
    },
    exporterList () {
      return this.$store.state.TeaGardenService.commonObj.masterExportCompanyList
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
      totalQuantity () {
        const looseTeaQty = parseInt(this.detailsFormData.loose_tea_quantity) ? parseInt(this.detailsFormData.loose_tea_quantity) : 0
        const packetTeaQty = parseInt(this.detailsFormData.packet_tea_quantity) ? parseInt(this.detailsFormData.packet_tea_quantity) : 0
        this.detailsFormData.total_quantity = looseTeaQty + packetTeaQty
      },
      totalFobValue () {
        const looseTeaFobValue = parseInt(this.detailsFormData.loose_tea_fob_value) ? parseInt(this.detailsFormData.loose_tea_fob_value) : 0
        const packetTeaFobValue = parseInt(this.detailsFormData.packet_tea_fob_value) ? parseInt(this.detailsFormData.packet_tea_fob_value) : 0
        this.detailsFormData.total_fob_value = looseTeaFobValue + packetTeaFobValue
      },
      cancelItem () {
          this.detailsFormData = {}
          this.editIndex = ''
          this.$refs.form1.reset()
      },
      editItem (item, index) {
          this.detailsFormData = Object.assign({}, item)
          this.editIndex = index
      },
      deleteItem (index) {
        this.$swal({
          title: this.$t('globalTrans.deleteConfirmMsg'),
          showCancelButton: true,
          confirmButtonText: this.$t('globalTrans.yes'),
          cancelButtonText: this.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.data.details.splice(index, 1)
            this.editIndex = ''
          }
        })
      },
      finalSave () {
      this.$swal({
        title: this.$t('globalTrans.final_save_msg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.status = 1
          this.saveUpdate()
        }
      })
    },
    async addItem () {
      let result = []
      if (this.editIndex !== '') {
          const editList = [...this.data.details]
          editList.splice(parseInt(this.editIndex), 1)
          result = [...editList]
      } else {
          result = this.data.details
      }
      const teaCompanyId = this.detailsFormData.export_company_id
      const newData = result.find(item => item.export_company_id === teaCompanyId)
      if (typeof newData === 'undefined') {
          if (this.editIndex !== '') {
              this.data.details[parseInt(this.editIndex)].export_company_id = this.detailsFormData.export_company_id
              this.data.details[parseInt(this.editIndex)].loose_tea_quantity = this.detailsFormData.loose_tea_quantity
              this.data.details[parseInt(this.editIndex)].loose_tea_fob_value = this.detailsFormData.loose_tea_fob_value
              this.data.details[parseInt(this.editIndex)].packet_tea_quantity = this.detailsFormData.packet_tea_quantity
              this.data.details[parseInt(this.editIndex)].packet_tea_fob_value = this.detailsFormData.packet_tea_fob_value
              this.data.details[parseInt(this.editIndex)].total_quantity = this.detailsFormData.total_quantity
              this.data.details[parseInt(this.editIndex)].total_fob_value = this.detailsFormData.total_fob_value
              this.data.details[parseInt(this.editIndex)].dollar_exchange_rate = this.detailsFormData.dollar_exchange_rate
              this.data.details[parseInt(this.editIndex)].date = this.detailsFormData.date
          } else {
             this.data.details.push(JSON.parse(JSON.stringify(this.detailsFormData)))
          }
          this.detailsFormData = {
            export_company_id: this.detailsFormData.export_company_id,
            loose_tea_quantity: '',
            loose_tea_fob_value: '',
            packet_tea_quantity: '',
            packet_tea_fob_value: '',
            total_quantity: '',
            total_fob_value: '',
            dollar_exchange_rate: '',
            date: ''
          }
          this.editIndex = ''
      } else {
          this.$toast.error({
              title: '!',
              message: 'Item already added',
              color: '#ee5253'
          })
      }
      // reset form
      this.$nextTick(() => {
          this.$refs.form1.reset()
      })
    },
    async saveUpdate () {
        var check = await this.$refs.form.validate()
        if (check) {
          if (!this.data.details.length) {
            return this.$toast.error({
              title: this.$t('globalTrans.error'),
              message: 'Add at least one item!',
              color: 'red'
            })
          }
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          this.loader = true
          this.data.status = this.status
          const loadingState = { loading: false, listReload: false }
          if (this.data.id) {
            result = await RestApi.putData(teaGardenServiceBaseUrl, `${countryWiseExportUpdate}/${this.data.id}`, this.data)
          } else {
            result = await RestApi.postData(teaGardenServiceBaseUrl, countryWiseExportStore, this.data)
          }
          this.loader = false
          loadingState.listReload = true
          this.$store.dispatch('mutateCommonProperties', loadingState)
          if (result.success) {
            this.$store.dispatch('TradeFairService/mutateCommonObj', { hasDropdownLoaded: false })
            this.$toast.success({
              title: this.$t('globalTrans.success'),
              message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
              color: '#D6E09B'
            })
            this.$bvModal.hide('modal-form')
            } else {
            this.$refs.form.setErrors(result.errors)
          }
        }
      },
      async getCountryWiseExportInfo () {
      this.loader = true
      const resultData = await RestApi.getData(teaGardenServiceBaseUrl, countryWiseExportShow + '/' + this.id)
      const tmpData = resultData.data
      const detailsData = tmpData.details.map(item => {
        return {
          export_company_id: item.export_company_id
        }
      })
      this.data.details = detailsData
      this.data = tmpData
      this.loader = false
    },
    getCompanyName (id) {
      const objectData = this.$store.state.TeaGardenService.commonObj.masterExportCompanyList.find(item => item.value === id)
      if (typeof objectData !== 'undefined') {
          return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
        } else {
          return ''
        }
    }
  }
}
</script>
