<template>
    <div class="inner-section">
      <card>
        <!-- search section start -->
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('teaGardenService.country_wise_export') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
          <b-row>
            <b-col sm="4">
              <b-form-group
                label-for="year"
              >
                <template v-slot:label>
                  {{ $t('globalTrans.year') }}
                </template>
                <b-form-select
                    plain
                    v-model="search.year"
                    :options="yearList"
                    id="year"
                    >
                    <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                label-for="month"
              >
                <template v-slot:label>
                  {{ $t('globalTrans.month') }}
                </template>
                <b-form-select
                    plain
                    v-model="search.month"
                    :options="monthList"
                    id="month"
                    >
                    <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                label-for="country_id"
              >
                <template v-slot:label>
                  {{ $t('globalTrans.country_name') }}
                </template>
                <b-form-select
                    plain
                    v-model="search.country_id"
                    :options="countryList"
                    id="country_id"
                    >
                    <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
              </b-button>
            </b-col>
          </b-row>
        </template>
        <!-- search section end -->
      </card>
      <body-card>
        <!-- table section start -->
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('teaGardenService.country_wise_export') }} {{ $t('globalTrans.list') }}</h4>
        </template>
        <template v-slot:headerAction>
          <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
        </template>
        <template v-slot:body>
          <b-overlay :show="loadingState">
            <b-row>
              <b-col sm="12">
                  <div class="quick-filter-wrapper">
                    <div class="quick-filter-left">
                      <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                        <b-dropdown-form>
                          <div
                            class="form-group"
                            v-for="(field, index) in labelData"
                            :key="index"
                            >
                              <b-form-checkbox
                                :id="'checkbox-' + field.labels"
                                v-model="field.show"
                                :name="'checkbox-' + field.labels"
                                value=1
                                unchecked-value=0
                              >
                                {{ $t(field.labels)  }}
                              </b-form-checkbox>
                          </div>
                        </b-dropdown-form>
                      </b-dropdown>
                    </div>
                    <div class="quick-filter-right">
                      <b-form-group
                        :label="$t('menu.perpage')"
                        label-for="per-page-select"
                      >
                        <b-form-select
                          id="per-page-select"
                          v-model="search.limit"
                          :options="pageOptions"
                          size="sm"
                        ></b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="table-responsive">
                <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                  <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                    <template v-slot:cell(serial)="data">
                      {{ $n(data.item.serial + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(year)="data">
                      <span>{{ $n(data.item.year, { useGrouping: false }) }}</span>
                    </template>
                    <template v-slot:cell(month)="data">
                      <span>{{ getMonthName(data.item.month) }}</span>
                    </template>
                    <template v-slot:cell(country)="data">
                      <span>{{ getCountryName(data.item.country_id) }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                        <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button :title="$t('globalTrans.details')" v-b-modal.modal-4 variant=" action-btn status" size="sm" @click="detailsData(data.item)"><i class="ri-eye-fill"></i></b-button>
                      <b-button v-if="data.item.status == 2" v-b-modal.modal-form variant=" iq-bg-success mr-1" size="sm" @click="editMethodInParent(data.item, 1)" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button>
                      <b-button class="moc-action-btn" :variant="data.item.status === 2 ? ' moc-secondary-btn' : ' moc-assign-btn'" :title="data.item.status === 2 ? $t('globalTrans.inactive') : $t('globalTrans.active')" @click="editMethodInParent(data.item, 2)">
                        <i :class="data.item.status === 2 ? 'ri-toggle-line' : 'ri-toggle-fill'"></i>
                      </b-button>
                      <router-link v-if="data.item.deadline >= today && data.item.status === 1" class="btn btn-sm btn-success"
                          :to="{name: 'tea_garden_service.garden.indent_submission', query: { circular_id: data.item.id }}"
                          :title="$t('globalTrans.apply')">
                          {{ $t('globalTrans.apply') }}
                      </router-link>
                    </template>
                  </b-table>
                </slot>
                <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                  <b-pagination
                    v-model="pagination.currentPage"
                    :perPage="search.limit"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                  />
                 </div>
              </b-col>
            </b-row>
          </b-overlay>
        </template>
        <!-- table section end -->
      </body-card>
      <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Form :id="editItemId" :key="editItemId"/>
      </b-modal>
      <b-modal id="modal-4" size="lg" :title="$t('teaGardenService.country_wise_export')" hide-footer ok-only ok-variant="danger">
        <Details :id="detailsItemId" :key="detailsItemId"/>
      </b-modal>
    </div>
  </template>
  <script>
  import Form from './Form.vue'
  import Details from './Details.vue'
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { countryWiseExportList, countryWiseExportToggle } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  import { helpers } from '@/utils/helper-functions'
  export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form, Details
    },
    data () {
      return {
        today: new Date().toJSON().slice(0, 10),
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        sortBy: '',
        sortDirection: 'desc',
        sortDesc: true,
        search: {
          year: 0,
          month: 0,
          country_id: 0,
          limit: 20
        },
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '7%' } },
          { labels: 'globalTrans.year', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '12%' } },
          { labels: 'globalTrans.month', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '12%' } },
          { labels: 'globalTrans.country_name', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '12%' } },
          { labels: 'globalTrans.status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '10%' } },
          { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 6, thStyle: { width: '15%' } }
        ],
        editItemId: 0,
        detailsItemId: 0,
        actions: {
          edit: true,
          toogle: true,
          delete: false
        }
      }
    },
    created () {
      this.loadData()
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      }
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
        let keys = []
        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'serial' },
            { key: 'year' },
            { key: 'month' },
            { key: 'country' },
            { key: 'status' },
            { key: 'action' }
          ]
        } else {
          keys = [
            { key: 'serial' },
            { key: 'year' },
            { key: 'month' },
            { key: 'country' },
            { key: 'status' },
            { key: 'action' }
          ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      pageOptions () {
        return this.$store.state.commonObj.pageOptions
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('teaGardenService.country_wise_export') + ' ' + this.$t('globalTrans.entry') : this.$t('teaGardenService.country_wise_export') + ' ' + this.$t('globalTrans.modify')
      },
      yearList () {
        return helpers.getYearList()
      },
      monthList () {
        return this.$store.state.commonObj.monthList.map(item => {
          return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
        })
      },
      countryList () {
        return this.$store.state.CommonService.commonObj.countryList.filter(item => item.status === 1)
      }
    },
    methods: {
      detailsData (item) {
        this.detailsItemId = item.id
      },
      editMethodInParent (item, type) {
        if (type === 1) {
          this.editItemId = item.id
          this.edit(item)
        } else if (type === 2) {
          this.changeStatus(3, teaGardenServiceBaseUrl, countryWiseExportToggle, item)
        }
      },
      searchData () {
        this.loadData()
      },
      async loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, countryWiseExportList, params)
        if (result.success) {
          const listData = result.data.data.map((item, index) => {
            return Object.assign({}, item, { serial: index })
          })
          this.$store.dispatch('setList', listData)
          this.paginationData(result.data, this.search.limit)
        } else {
          this.$store.dispatch('setList', [])
          this.paginationData([])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      getMonthName (id) {
        const obj = this.$store.state.commonObj.monthList.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getCountryName (id) {
        const obj = this.$store.state.CommonService.commonObj.countryList.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      }
    }
  }
  </script>
