<template>
  <b-row>
    <b-col lg="12" sm="12">
        <template>
          <b-overlay :show="loading">
            <b-row>
              <b-col lg="12" sm="12">
                    <b-table-simple small resonsive borderless>
                      <b-tr>
                        <b-th style="width:20%" class="text-left">{{ $t('globalTrans.year') }}</b-th>
                        <b-th style="width:5%" class="text-left">:</b-th>
                        <b-td style="width:80%" class="text-left">{{ EngBangNum(detailsData.year.toString()) }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th class="text-left">{{ $t('globalTrans.month') }}</b-th>
                        <b-th class="text-left">:</b-th>
                        <b-td class="text-left">{{ getMonthName(detailsData.month)}}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th class="text-left">{{$t('globalTrans.country_name')}}</b-th>
                        <b-th class="text-left">:</b-th>
                        <b-td class="text-left">{{ getCountryName(detailsData.country_id)}}</b-td>
                      </b-tr>
                  </b-table-simple>
                  <template>
                    <h6 style="background: #B6D0E2; " class="text-sm-center">{{ $t('teaGardenService.export_info_quantity') }} {{ $t('globalTrans.details') }}</h6>
                  </template>
                <b-table-simple bordered>
                  <thead>
                    <tr class="bg-primary">
                      <th style="width:7%" rowspan="2">{{ $t('globalTrans.sl_no') }}</th>
                      <!-- <th style="width:10%" rowspan="2">{{ $t('globalTrans.country_name') }}</th> -->
                      <th style="width:10%" rowspan="2" class="text-center">{{ $t('teaGardenService.exporter_name') }}</th>
                      <th style="width:10%" colspan="2" class="text-center">{{ $t('teaGardenService.loose_tea') }}</th>
                      <th style="width:10%" colspan="2" class="text-center">{{ $t('teaGardenService.packet_tea') }}</th>
                      <th style="width:10%" colspan="2" class="text-center">{{ $t('globalTrans.total') }}</th>
                    </tr>
                    <tr class="bg-light">
                      <th style="width:10%" class="text-center">{{ $t('teaGardenService.quantity') }}</th>
                      <th style="width:10%" class="text-center">{{ $t('teaGardenService.fob_value') }}</th>
                      <th style="width:10%" class="text-center">{{ $t('teaGardenService.quantity') }}</th>
                      <th style="width:10%" class="text-center">{{ $t('teaGardenService.fob_value') }}</th>
                      <th style="width:10%" class="text-center">{{ $t('teaGardenService.quantity') }}</th>
                      <th style="width:10%" class="text-center">{{ $t('teaGardenService.fob_value') }}</th>
                    </tr>
                  </thead>
                  <b-tbody>
                      <b-tr v-for="(item, index) in detailsData.details" :key="index">
                          <b-td>
                              {{ $n(index + 1) }}
                          </b-td>
                          <b-td>
                            {{ getExportCompanyName(item.export_company_id) }}
                          </b-td>
                          <b-td>
                            {{ $n(item.loose_tea_quantity) }}
                          </b-td>
                          <b-td>
                            {{ $n(item.loose_tea_fob_value) }}
                          </b-td>
                          <b-td>
                            {{ $n(item.packet_tea_quantity) }}
                          </b-td>
                          <b-td>
                            {{ $n(item.packet_tea_fob_value) }}
                          </b-td>
                          <b-td>
                            {{ $n(item.total_quantity) }}
                          </b-td>
                          <b-td>
                            {{ $n(item.total_fob_value) }}
                          </b-td>
                      </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-overlay>
        </template>
    </b-col>
  </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { countryWiseExportShow } from '../../api/routes'

export default {
  name: 'Details',
  props: ['id'],
  data () {
    return {
      loading: false,
      detailsData: {
        year: '',
        month: '',
        country_id: '',
        details: []
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  async created () {
    if (this.id) {
      this.loading = true
     await this.getCountryWiseExportInfo()
    }
  },
  methods: {
  EngBangNum (n) {
    if (this.$i18n.locale === 'bn') {
      return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
    } else {
      return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
    }
  },
  async getCountryWiseExportInfo () {
      this.loading = true
      const resultData = await RestApi.getData(teaGardenServiceBaseUrl, countryWiseExportShow + '/' + this.id)
      if (resultData.success) {
        this.detailsData = resultData.data
        this.loading = false
      } else {
        this.detailsData = {}
        this.loading = false
      }
    },
    getCompanyName (id) {
      const objectData = this.$store.state.TeaGardenService.commonObj.masterTeaCompanyList.find(item => item.value === id)
      if (typeof objectData !== 'undefined') {
          return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
        } else {
          return ''
        }
    },
    getExportCompanyName (id) {
      const objectData = this.$store.state.TeaGardenService.commonObj.masterExportCompanyList.find(item => item.value === id)
      if (typeof objectData !== 'undefined') {
          return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
        } else {
          return ''
        }
    },
      getMonthName (id) {
        const obj = this.$store.state.commonObj.monthList.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getCountryName (id) {
        const obj = this.$store.state.CommonService.commonObj.countryList.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      }
  }
}
</script>
